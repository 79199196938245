<template>
  <v-dialog
    width="600"
    scrollable
    v-model="dialog"
    >
    <template v-slot:activator="{ on, attrs }">
      <v-list-item
        v-bind="attrs"
        v-on="on"
        v-if="post.profile.id == currentUser.selectedProfile.id"
        >
        <v-list-item-icon>
          <v-icon
            color="primary"
            >
            mdi-pencil
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content
          >
          <v-list-item-title>
            Editar
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>

    <v-card
      v-if="dialog"
      style="display: flex; flex-direction: column"
      >
      <v-card-title
        class="justify-center"
        >
        Editar publicación
        <v-btn
          icon
          class="mr-4"
          @click="dialog = false"
          style="position: absolute; right: 0"
          >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text
        class="pt-3"
        >
        <v-form
          class="d-flex flex-column"
          v-model="valid"
          ref="form"
          lazy-validation
          >
          <v-textarea
            rows="3"
            outlined
            flat
            :label="$t('fields.body')"
            class="rounded-lg"
            v-model="attributes.body"
            :rules="[ v => !!v || 'El campo es obligatorio']"></v-textarea>
        </v-form>

        <div
          class="fill-width"
          >
          <v-btn
            :loading="loading"
            :disabled="loading"
            block
            class="rounded-lg"
            color="primary"
            @click="editPost"
            >
            Editar publicación
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style lang="sass" scoped>
  #images-container
    .v-btn
      opacity: 0.8
$btn-hover-opacity: 1 !default      
</style>

<script>
import { mapGetters } from 'vuex'
import { EditPost } from '@/graphql/mutations/posts'

export default {
  name: 'EditPost',

  data: () => ({
    dialog: false,
    loading: false,
    valid: true,
    attributes: {
      body: null,
    }
  }),

  props: {
    post: {
      type: Object,
      required: true,
    },
  },

  created () {
    this.attributes.body = this.post.body
  },

  computed: {
    ...mapGetters(['currentUser'])
  },

  methods: {
    editPost () {
      if (this.valid = this.$refs.form.validate()) {
        this.loading = true
        this.$apollo.mutate({
          mutation: EditPost,
          variables: {
            input: {
              id: this.post.id,
              attributes: {
                ...this.attributes
              }
            }
          }
        }).then ( res => {
          this.$emit('reload')
          this.dialog = false
          this.loading = false
        }).catch ( err => {
          this.loading = false
        })
      }
    }
  },
}
</script>
